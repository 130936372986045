<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="reports_types"
      class="elevation-0"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:no-data> Aucun type de rapport </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">Types de rapports </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="870px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="openAddDialog"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.name"
                          label="Type de rapport*"
                          id="name"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <ckeditor
                          class="custom-ck-editor"
                          @ready="onReady"
                          :editor="editor"
                          v-model="editedItem.template"
                          :config="editorConfig"
                        ></ckeditor>
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                  :loading="loading"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  @clicked="save(false)"
                  v-if="!showEditButton"
                  :loading="loadingAdd"
                />
                <Button
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  v-if="showEditButton"
                  :loading="loadingEdit"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Etes vous sûr de supprimer ce type de rapport ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-1" text @click="dialogDelete = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="secondary darken-1"
                  text
                  @click="deleteLine"
                  :loading="loadingDelete"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" small @click="downloadItem(item)">mdi-download</v-icon>
        <v-icon class="mr-2" small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import { Button, TextInput } from "@/components/base"
import { infinityScrollMixin } from "@/utils/infinityScrollMixin"
import {
  addTemplate,
  deleteTemplate,
  getAllTemplatesPaginated,
  updateTemplate
} from "@/services/reportsTypes"
export default {
  components: { Button, TextInput },
  mixins: [infinityScrollMixin],
  data: () => ({
    dialogDelete: false,
    editor: DecoupledEditor,
    editorConfig: {
      toolbar: {
        removeItems: ["uploadImage", "mediaEmbed"],
        shouldNotGroupWhenFull: true
      }
    },
    loading: false,
    loadingAdd: false,
    loadingEdit: false,
    isValid: true,
    showEditButton: false,
    dialog: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Nom", value: "name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    reports_types: [],
    editedIndex: -1,
    editedItem: {
      template: ""
    },
    defaultItem: {},
    pageNumber: 1,
    last_page: 0,
    loadingDelete: false
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau type de rapport"
        : "Modifier type de rapport"
    }
  },
  watch: {
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1
        getAllTemplatesPaginated(this.pageNumber).then((response) => {
          this.reports_types = [...this.reports_types, ...response.data.data]
          this.last_page = response.data.last_page
        })
      }
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },
    deleteLine() {
      deleteTemplate(this.editedItem.id).then((response) => {
        this.reports_types = response.data.data
        this.last_page = response.data.last_page
        this.dialogDelete = false
      })
    },
    deleteItem(item) {
      this.dialogDelete = true
      this.editedItem = item
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.dialog = true
    },
    initialize() {
      getAllTemplatesPaginated(this.pageNumber).then((response) => {
        this.reports_types = response.data.data
        this.last_page = response.data.last_page
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.showEditButton = false
      })
    },
    update() {
      this.loadingEdit = true
      updateTemplate(this.editedItem).then(() => {
        this.loadingEdit = false
        this.showEditButton = false
        Object.assign(this.reports_types[this.editedIndex], this.editedItem)
        this.close()
        this.$swal.fire({ title: "Type de rapport modifier avec succès" })
      })
    },
    editItem(item) {
      this.showEditButton = true
      this.editedIndex = this.reports_types.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate()
        return
      }
      dialogStat ? (this.loading = true) : (this.loadingAdd = true)
      addTemplate(this.editedItem).then((response) => {
        dialogStat ? (this.loading = false) : (this.loadingAdd = false)
        this.reports_types.push(response.data)
        this.close()
        this.$refs.form.reset()
        this.dialog = dialogStat
        this.$swal.fire({ title: "Type de rapport ajouté avec succès" })
      })
    }
  }
}
</script>
<style></style>
